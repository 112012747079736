export default [{
  path: '/pedidos',
  component: () => import('./views/OrdersWrapper.vue'),
  children: [
    {
      path: '',
      component: () => import('./views/Orders.vue'),
      name: 'orders',
    },
  ],
}];
