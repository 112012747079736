import axios from 'axios';
import globals from '../globals';

import authUtil from '../util/util.auth';

const ParcelexApi = axios.create({ baseURL: globals().apiUrl });

ParcelexApi.interceptors.request.use(async (config) => {
  const token = authUtil.getToken();
  // eslint-disable-next-line no-param-reassign
  config.headers.Authorization = `Bearer ${token}`;
  return config;
});

ParcelexApi.interceptors.response.use((response) => response, (error) => {
  if (error.response.status === 401) {
    authUtil.unsetAuth();
  }
  return Promise.reject(error);
});

export default ParcelexApi;
