function setAuth({ userId, username, token, retailerId, name, lastname, hierarchy }) {
  if (userId) localStorage.setItem('userId', userId);
  if (username) localStorage.setItem('username', username);
  if (token) localStorage.setItem('token', token);
  if (retailerId) localStorage.setItem('retailerId', retailerId);
  if (name && lastname) localStorage.setItem('intraUserName', `${name} ${lastname}`);
  if (hierarchy) localStorage.setItem('hierarchy', hierarchy);
}

function setToken({ token }) {
  localStorage.setItem('token', token);
}

function unsetToken() {
  localStorage.removeItem('token');
}

function unsetAuth() {
  localStorage.clear();
}

function getUserId() {
  return localStorage.getItem('userId');
}

function getUsername() {
  return localStorage.getItem('username');
}

function getToken() {
  return localStorage.getItem('token');
}

function setRetailerId({ retailerId }) {
  localStorage.setItem('retailerId', retailerId);
}

function getRetailerId() {
  return localStorage.getItem('retailerId');
}

function getStepPath({ step }) {
  const stepHandlers = {
    FIRST_STEP: 'onboarding/dados-cadastrais',
    SECOND_STEP: 'onboarding/endereco-comercial',
    THIRD_STEP: 'onboarding/contatos',
    FINAL_STEP: 'onboarding/instalacao',
    IN_ANALYSIS: 'onboarding/cadastro-analise',
    INSTALLATION: 'onboarding/instalacao-nuvemshop',
    REJECTED: 'onboarding/cadastro-reprovado',
  };
  return stepHandlers[step];
}

function getIntraUserName() {
  return localStorage.getItem('intraUserName');
}

export default {
  setAuth,
  setToken,
  unsetAuth,
  getUserId,
  getUsername,
  getToken,
  unsetToken,
  getStepPath,
  getRetailerId,
  setRetailerId,
  getIntraUserName,
};
