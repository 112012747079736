import OrdersService from './orders.service';

export default {
  namespaced: true,
  state: {
    totalOfOrders: 0,
    orders: [],
  },
  actions: {
    async getOrders({ commit }, {
      retailerId,
      startDate,
      endDate,
      search,
      status,
      paymentStatus,
      limit,
      offset,
      sort,
      canViewAll,
      intraUserId,
    }) {
      try {
        const { orders, totalOfOrders } = await OrdersService.getOrdersByRetailer({
          retailerId,
          startDate,
          endDate,
          search,
          status,
          paymentStatus,
          limit,
          offset,
          sort,
          canViewAll,
          intraUserId,
        });

        commit('setOrders', { orders });
        commit('setTotalOfOrders', { totalOfOrders });
      } catch (error) {
        if (!error.response) {
          console.error(error);
          throw error;
        }
      }
    },
  },
  mutations: {
    setOrders(state, { orders }) {
      state.orders = orders;
    },
    setTotalOfOrders(state, { totalOfOrders }) {
      state.totalOfOrders = parseInt(totalOfOrders, 10);
    },
  },
};
