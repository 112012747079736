<template>
  <div id="app" class="overflow-x-hidden">
    <router-view/>
    <px-block-page spinnerImageSrc="https://cdn.parcelex.com.br/assets/v2/x-icon.svg"/>
    <px-toast-notify/>
  </div>
</template>

<style rel="stylesheet" src="../node_modules/px-vue-alert/dist/px-vue-alert.css"></style>

<script>
import { mapState } from 'vuex';

export default {
  metaInfo: {
    title: 'Home',
    titleTemplate: '%s | Parcelex',
  },
  computed: {
    ...mapState('auth', ['token']),
  },
  methods: {
    // ...mapActions('auth', ['setToken']),
  },
  // watch: {
  //   $route(newValue) {
  //     const { onboardingToken, authToken } = newValue.query;
  //     const token = onboardingToken || authToken;
  //     if (token && !this.token) this.setToken({ token });
  //   },
  // },
};
</script>

<style lang="scss">
  #prlx-icon {
    width: 60px !important;
  }
</style>
