/* eslint-disable func-names */
/* eslint-disable max-len */
import jwtDecode from 'jwt-decode';
import Vue from 'vue';
import VueRouter from 'vue-router';
import VueMeta from 'vue-meta';

import globals from '@/globals';

import authUtil from './util/util.auth';

import LoginRouter from './feature/login/login.router';
import OrdersRouter from './feature/orders/orders.router';
import FinanceRouter from './feature/finance/finance.router';
import ReportsRouter from './feature/reports/reports.router';
import IntegrationRouter from './feature/integration/integration.router';
import DashboardRouter from './feature/dashboard/dashboard.router';
import StyleguideRouter from './feature/styleguide/styleguide.router';
import Onboarding from './feature/onboarding/onboarding.router';
import PaymentLinkRouter from './feature/paymentLink/paymentLink.router';

Vue.use(VueRouter);
Vue.use(VueMeta);

const routes = [
  {
    path: '/',
    component: () => import('./components/GlobalLoadingWrapper.vue'),
    children: [
      {
        path: '/',
        component: () => import('./views/Home.vue'),
        name: 'home',
      },
      ...LoginRouter,
      ...Onboarding,
      ...OrdersRouter,
      ...FinanceRouter,
      ...ReportsRouter,
      ...IntegrationRouter,
      ...DashboardRouter,
      ...StyleguideRouter,
      ...PaymentLinkRouter,
      {
        path: '*',
        name: 'not-found',
        component: () => import('./views/NotFound.vue'),
      },

    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

function patchRouterMethod(routerInstance, methodName) {
  // eslint-disable-next-line no-param-reassign
  routerInstance[`old${methodName}`] = routerInstance[methodName];
  // eslint-disable-next-line no-param-reassign
  routerInstance[methodName] = async function (location) {
    return router[`old${methodName}`](location).catch((error) => {
      if (error.name === 'NavigationDuplicated') {
        return this.currentRoute;
      }
      throw error;
    });
  };
}

patchRouterMethod(router, 'push');
patchRouterMethod(router, 'replace');

router.beforeEach((to, _from, next) => {
  const { authToken, authUser, makeLogout } = to.query;

  const localStorageToken = authUtil.getToken();
  const localStorageUsername = authUtil.getUsername();

  const token = localStorageToken || authToken;
  const username = localStorageUsername || authUser;
  const accessibleUrls = [
    '/login',
    '/onboarding/cadastro-aprovado',
    '/registrar',
    '/recuperar-senha',
    '/verificar-email',
    '/alterar-senha',
    '/termos-e-condicoes',
    '/registrar/autenticacao',
  ];

  const jwtDecoded = token && !['null', 'undefined'].includes() && jwtDecode(token);
  const tokenExpired = !jwtDecoded || Date.now() >= jwtDecoded.exp * 1000;

  if (makeLogout || (tokenExpired && !accessibleUrls.includes(to.path))) {
    authUtil.unsetAuth();
    return setTimeout(() => next('/login'), 10);
  }

  if (to.path === '/login' && !tokenExpired) {
    return setTimeout(() => {
      window.location.href = `${globals().centralUrl}?authToken=${token}&authUser=${username}`;
    }, 10);
  }

  if (!localStorageToken || !localStorageUsername) authUtil.setAuth({ token, username });

  return setTimeout(() => next(), 10);
});

export default router;
