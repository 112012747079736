import reportsService from './reports.service';
import utilAuth from '../../util/util.auth';

export default {
  namespaced: true,
  state: {
    totalOfReports: 0,
    reports: [],
    reportTypes: [
      {
        value: 'transactions',
        text: 'Extrato de Transações Padrão',
      },
      {
        value: 'finance',
        text: 'Extrato Financeiro Padrão',
      },
    ],
  },
  actions: {
    async getReports({ commit }, { limit, offset, sort }) {
      try {
        const retailerId = utilAuth.getRetailerId();
        const res = await reportsService.getReportsByRetailer({
          retailerId,
          limit,
          offset,
          sort,
        });
        const totalCount = res.headers['x-total-count'.toLowerCase()];
        commit('setTotalOfReports', { totalCount });
        commit('setReports', { reports: res.data });
      } catch (error) {
        if (!error.response) {
          console.error(error);
          throw error;
        }
      }
    },
    async generateReport(_, { layout, body, retailerId }) {
      try {
        await reportsService.generateReport({ layout, body, retailerId });
      } catch (error) {
        if (!error.response) {
          console.error(error);
          throw error;
        }
      }
    },
  },
  mutations: {
    setReports(state, { reports }) {
      state.reports = reports;
    },
    setTotalOfReports(state, { totalCount }) {
      state.totalOfReports = parseInt(totalCount, 10);
    },
  },
};
