export default [
  {
    path: '/template-login',
    component: () => import('./views/TemplateLogin.vue'),
    name: 'template-login',
  },
  {
    path: '/template-registration',
    component: () => import('./views/TemplateRegistration.vue'),
    name: 'template-registration',
  },
  {
    path: '/login',
    component: () => import('./views/Login.vue'),
    name: 'login',
  },
  {
    path: '/registrar',
    component: () => import('./views/Signup.vue'),
    name: 'signup',
  },
  {
    path: '/registrar/autenticacao',
    component: () => import('./views/Authenticate.vue'),
    name: 'authenticate',
  },
  {
    path: '/termos-e-condicoes',
    component: () => import('./views/TermsAndConditions.vue'),
    name: 'terms-and-conditions',
  },
  {
    path: '/recuperar-senha',
    component: () => import('./views/RecoverPassword.vue'),
    name: 'recovery-password',
  },
  {
    path: '/verificar-email',
    component: () => import('./views/VerifyEmail.vue'),
    name: 'verify-email',
  },
  {
    path: '/alterar-senha',
    component: () => import('./views/ChangePassword.vue'),
    name: 'change-password',
  },
  {
    path: '/404',
    component: () => import('./views/404.vue'),
    name: '404',
  },
  {
    path: '/500',
    component: () => import('./views/500.vue'),
    name: '500',
  },
  {
    path: '/loader',
    component: () => import('./views/Loader.vue'),
    name: 'loader',
  },
  {
    path: '/success',
    component: () => import('./views/Success.vue'),
    name: 'success',
  },
];
