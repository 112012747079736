import Vue from 'vue';
import VModal from 'vue-js-modal';
import VueWindowSize from 'vue-window-size';
import VTooltip from 'v-tooltip';
import * as Sentry from '@sentry/browser';
import { Vue as VueIntegration } from '@sentry/integrations';
import { Integrations } from '@sentry/tracing';
import PxVueAlert from 'px-vue-alert';
import App from './App.vue';
import router from './router';
import store from './store';
import './index.css';

if (process.env.VUE_APP_SENTRY_ENV) {
  Sentry.init({
    dsn: 'https://983fb195005842da8c43b461c3e790b2@o426174.ingest.sentry.io/5456223',
    environment: process.env.VUE_APP_SENTRY_ENV,
    integrations: [
      new VueIntegration({
        Vue,
        tracing: true,
      }),
      new Integrations.BrowserTracing(),
    ],
    tracesSampleRate: 1.0,
  });
}

Vue.use(VModal);
Vue.use(VTooltip);
Vue.use(VueWindowSize, {
  delay: 200,
});
Vue.use(PxVueAlert, { store });

Vue.config.productionTip = false;

const waitForStorageToBeReady = async (to, from, next) => {
  await store.restored;
  next();
};
router.beforeEach(waitForStorageToBeReady);

Vue.directive('click-outside', {
  bind(el, binding, vnode) {
    // eslint-disable-next-line no-param-reassign
    el.clickOutsideEvent = (event) => {
      if (!(el === event.target || el.contains(event.target))) {
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener('click', el.clickOutsideEvent);
  },
  unbind(el) {
    document.body.removeEventListener('click', el.clickOutsideEvent);
  },
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
