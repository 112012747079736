import ParcelexApi from '../../services/parcelex';

function getAuthHeader() {
  const token = localStorage.getItem('token');
  if (!token) return {};
  return { Authorization: `Bearer ${token}` };
}

export default {
  async getPaymentLinks({
    retailerId,
    search,
    limit,
    offset,
    status,
    showCreditDenied,
    ctrId,
    sortInfo = {},
  }) {
    const queryObject = {
      retailerId,
      limit,
      offset,
      showCreditDenied,
      ignoreStatus: 'PENDING',
      sortField: sortInfo.field,
      sortType: sortInfo.type,
    };

    if (status) queryObject.status = status;
    if (search) queryObject.search = search;
    if (ctrId) queryObject.ctrId = ctrId.toString();

    const query = new URLSearchParams(queryObject).toString();
    return ParcelexApi.get(`paymentLink?${query}`);
  },
  createPaymentLink({
    retailerId,
    ctrId,
    creatorEmail,
    creatorName,
    customerName,
    customerPhone,
    customerEmail,
    description,
    value,
  }) {
    const paymentLinkData = {
      retailerId,
      ctrId,
      creatorEmail,
      creatorName,
      customerName,
      customerPhone,
      description,
      value,
    };

    if (customerEmail) paymentLinkData.customerEmail = customerEmail;

    return ParcelexApi.post('paymentLink', paymentLinkData);
  },
  verifyCreditbyCPF({ cpf }) {
    return ParcelexApi.get(`paymentLink/preApprovedCredit/${cpf}`);
  },
  updatePaymentUser({ cpf, user }) {
    const { email, zipCode, phone } = user;
    return ParcelexApi.patch(`user/update/${cpf}`, { email, zipCode, phone });
  },
  createPreApprovedPaymentLink({
    retailerId,
    ctrId,
    creatorEmail,
    creatorName,
    customerCpf,
    customerEmail,
    customerZipCode,
    customerPhone,
  }) {
    const paymentLinkData = {
      retailerId,
      ctrId,
      creatorEmail,
      creatorName,
      customerCpf,
      customerEmail,
      customerZipCode,
      customerPhone,
    };

    return ParcelexApi.post('paymentLink/pre-approve', paymentLinkData);
  },
  applyForPreApprovedPaymentLink({ paymentLinkId, value, description, device }) {
    return ParcelexApi.post(`paymentLink/pre-approve/${paymentLinkId}/apply`, {
      value,
      description,
      device,
    });
  },
  applyForPaymentLink({ paymentLinkId, userInfo, device }) {
    const {
      cpf,
      email,
      zipCode,
    } = userInfo;

    return ParcelexApi.post(`paymentLink/${paymentLinkId}/apply`, {
      cpf,
      email,
      zipCode,
      device,
    });
  },
  generateLink({ paymentLinkId, rentGuaranteeBillet }) {
    return ParcelexApi.post(`paymentLink/${paymentLinkId}/link`, {
      rentGuaranteeBillet,
    });
  },
  deletePaymentLink({ paymentLinkId }) {
    return ParcelexApi.delete(`paymentLink/${paymentLinkId}`);
  },
  cancelTransaction({ apiKey, transactionId, retailerCancelReason }) {
    const transactionData = {
      apiKey,
      transactionId,
      motivo: retailerCancelReason,
      isCanceledByRetailer: true,
    };
    return ParcelexApi.post('public/cancel', transactionData);
  },
  updateOrderId({ paymentLinkId, orderId }) {
    return ParcelexApi.patch(`paymentLink/${paymentLinkId}/orderId`, { orderId });
  },
  getPaymentOptions({ paymentLinkId }) {
    return ParcelexApi.get(`paymentLink/${paymentLinkId}/options`);
  },
  getPaymentOptionChosen({ paymentLinkId }) {
    return ParcelexApi.get(`paymentLink/${paymentLinkId}/options/chosen`);
  },
  getPaymentOptionOnboardingStep({ paymentLinkId }) {
    return ParcelexApi.get(`paymentLink/${paymentLinkId}/onboarding-step`);
  },
  getCreators({ retailerId }) {
    return ParcelexApi.get(`paymentLink/${retailerId}/creators`);
  },
  async getMaxValueApproved({ paymentLinkId }) {
    return ParcelexApi.get(`paymentLink/${paymentLinkId}/options/approvedCredit`);
  },
  getDataForPaymentLinkCreation({ preApprovedCreditId, cpf }) {
    const query = new URLSearchParams({ preApprovedCreditId, cpf }).toString();

    return ParcelexApi.get(`paymentLink/dataForCreation/query?${query}`);
  },
  async getInstallmentsBilletPDF({ transactionId }) {
    const response = await ParcelexApi.get(`/central/transactions/${transactionId}/billets`, {
      headers: getAuthHeader(),
    });
    return response.data;
  },
};
