import querystring from 'querystring';
import ParcelexApi from '@/services/parcelex';

function getAuthHeader() {
  const token = localStorage.getItem('token');
  if (!token) return {};
  return { Authorization: `Bearer ${token}` };
}

async function getTransfersData({
  startDate,
  endDate,
  retailerId,
  search,
  limit,
  offset,
  channel,
  sort,
}) {
  const queryObject = {
    startDate, endDate, retailerId, limit, offset, channel, sort: JSON.stringify(sort),
  };
  if (search) queryObject.search = search;
  const queryParams = querystring.stringify(queryObject);
  const response = await ParcelexApi.get(`/central/transfers?${queryParams}`, {
    headers: getAuthHeader(),
  });
  return {
    transfers: response.data,
    totalOfTransfers: response.headers['x-total-count'.toLowerCase()],
    totalOfAmount: response.headers['x-total-amount'.toLowerCase()],
  };
}

async function getTransactionsToTransferData({
  startDate,
  endDate,
  retailerId,
  search,
  limit,
  offset,
  channel,
  sort,
}) {
  const queryObject = {
    startDate, endDate, retailerId, limit, offset, channel, sort: JSON.stringify(sort),
  };
  if (channel) queryObject.channel = channel;
  if (search) queryObject.search = search;
  const queryParams = querystring.stringify(queryObject);
  const response = await ParcelexApi.get(`/central/transactionsToTransfer?${queryParams}`, {
    headers: getAuthHeader(),
  });
  return {
    pendingTransfers: response.data,
    totalOfPendingTransfers: response.headers['x-total-count'.toLowerCase()],
    totalOfAmount: response.headers['x-total-amount'.toLowerCase()],
  };
}

export default {
  getTransfersData,
  getTransactionsToTransferData,
};
