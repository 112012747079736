import ParcelexApi from '@/services/parcelex';
import ReportxApi from '@/services/reportx';

function getAuthHeader() {
  const token = localStorage.getItem('token');
  if (!token) return {};
  return { Authorization: `Bearer ${token}` };
}

async function generateReport({ layout, body, retailerId }) {
  return ReportxApi.post(
    `/central/reports/${layout}/${retailerId}`,
    body,
    { headers: getAuthHeader() },
  );
}

async function getReportsByRetailer({ retailerId, limit, offset, sort }) {
  const queryObject = {};
  if (limit) queryObject.limit = limit;
  if (offset) queryObject.offset = offset;
  if (sort) queryObject.sort = sort;
  const query = new URLSearchParams(queryObject).toString();
  return ParcelexApi.get(
    `/central/reports/${retailerId}?${query}`,
    { headers: getAuthHeader() },
  );
}

function downloadFromS3(path) {
  return ParcelexApi
    .get(`central/s3/${path}`, {
      headers: getAuthHeader(),
      responseType: 'arraybuffer',
    })
    .then((response) => response.data);
}

export default {
  downloadFromS3,
  generateReport,
  getReportsByRetailer,
};
