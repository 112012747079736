function setRetailerId(retailerId) {
  if (retailerId) localStorage.setItem('retailerId', retailerId);
}

function unsetRetailerId() {
  localStorage.removeItem('retailerId');
}

function getRetailerId() {
  return localStorage.getItem('retailerId');
}

function setUserName(userName) {
  if (userName) localStorage.setItem('userName', userName);
}

function unsetUserName() {
  localStorage.removeItem('userName');
}

function getUserName() {
  return localStorage.getItem('UserName');
}

export default {
  setRetailerId,
  unsetRetailerId,
  getRetailerId,
  setUserName,
  unsetUserName,
  getUserName,
};
