import utilAuth from '../../util/util.auth';
import paymentLinkService from './paymentLink.service';
import isMobile from '../../lib/isMobile';

const errorDictionary = {
  'Invalid email': 'email',
  'Invalid zipCode': 'zipCode',
  'Payment link not found': 'Link de pagamento não encontrado',
  'Invalid permission for this payment link': 'Permissão para o link de pagamento inválida',
  'Cannot found payment link': 'Link de pagamento não encontrado',
  'Cannot find payment link': 'Link de pagamento não encontrado',
  'Cannot create payment link': 'Não foi possível criar link de pagamento',
  'Cannot found checkout': 'Não foi possível criar o checkout',
  'Already applied for payment link': 'Link de pagamento já foi aplicado',
  'Retailer not found': 'Varejista não encontrado',
  'User cannot buy now, try later': 'Usuário não pode realizar compra agora, tente novamente mais tarde',
  'Payment link already sent': 'Link de pagamento já enviado',
  'Payment link has no transaction': 'Link de pagamento não possui transação',
  'Payment link has no onboarding': 'Link de pagamento não possui onboarding',
  'Max daily tries of payment link creation reached': 'Numero máximo de tentativas diarias de criação de links de pagemento atingido',
};

function filterValue({ field, value }) {
  const filters = {
    phone: value && value.replace(/[() -]/g, ''),
    cpf: value && value.replace(/[.-]/g, ''),
    zipCode: value && value.replace('-', ''),
  };
  return filters[field] || value;
}

function handleError(error) {
  const hasErrorOnDictionary = errorDictionary[error.message];

  if (hasErrorOnDictionary) {
    throw Object.assign(
      new Error(error),
      {
        response: {
          data: errorDictionary[error.message],
        },
      },
    );
  }

  throw error;
}

function sanitizeUser({ user }) {
  return {
    phone: filterValue({ field: 'phone', value: user.phone }),
    cpf: filterValue({ field: 'cpf', value: user.cpf }),
    email: user.email,
    zipCode: filterValue({ field: 'zipCode', value: user.zipCode }),
  };
}

export default {
  namespaced: true,
  state: {
    paymentLinks: [],
    totalOfPaymentLinks: 0,
    newPaymentLink: {},
    paymentLinkUser: {
      phone: null,
      cpf: null,
      email: null,
      zipCode: null,
    },
    verifyCreditResult: null,
    allowedToUsePaymentLink: true,
    paymentOptions: null,
    chosenPaymentOption: null,
    onboardingStep: null,
    creators: [],
    isDrawerLoading: false,
    maxValueApproved: null,

    verifyPreApprovedCreditResult: null,
    preApprovedCredit: null,
    currentPaymentLink: null,
    retailerRetentionType: null,
    dailyTries: 0,
    expirationDate: '',
  },
  getters: {
    pendency(state) {
      const { currentPaymentLink, onboardingStep } = state;
      const { creditApplyStatus, status: paymentLinkStatus } = currentPaymentLink || {};

      if (['EXPIRED', 'CANCELED'].includes(paymentLinkStatus) || creditApplyStatus === 'DENIED') return null;

      switch (onboardingStep) {
        case 'REFUSED':
        case 'COMPLETE':
          return null;
        case 'FRONTDOC':
        case 'BACKDOC':
          return 'Aguardando envio do documento';
        case 'SELFIE':
          return 'Aguardando envio da selfie';
        case 'DESK':
          return 'Documentação em análise';
        case 'ENTRY':
          return 'Aguardando pagamento da entrada';
        case 'WAITING':
        case 'CONFIRMATION':
          return 'Aguardando verificação do celular';
        case 'PENDING_VERIFY_CREDIT':
          return 'Aguardando verificação de crédito';
        default:
          return 'Aguardando seleção do parcelamento';
      }
    },
    dailyTries(state) {
      return state.dailyTries;
    },
    getExpirationDate(state) {
      return state.expirationDate;
    },
    getPreApprovedCredit(state) {
      return state.preApprovedCredit;
    },
    isPromotionalRetention(state) {
      return state.retailerRetentionType === 'all' || state.retailerRetentionType === 'promotional';
    },
  },
  actions: {
    async getPaymentLinks({ commit }, {
      search,
      limit,
      offset,
      status,
      showCreditDenied,
      ctrId,
      sortInfo,
    }) {
      try {
        const retailerId = utilAuth.getRetailerId();

        const res = await paymentLinkService.getPaymentLinks({
          retailerId,
          search,
          limit,
          offset,
          status,
          showCreditDenied,
          ctrId,
          sortInfo,
        });
        const totalCount = res.headers['x-total-count'];
        commit('setTotalOfPaymentLinks', { totalCount });
        commit('setPaymentLinks', { paymentLinks: res.data });
        commit('setAllowedToUsePaymentLink', true);
      } catch (error) {
        if (!error.response) {
          throw error;
        }

        const { status: statusError, data } = error.response;
        if (statusError === 403 && data === 'retailer has no permission for this product') {
          commit('setAllowedToUsePaymentLink', false);
        } else {
          console.error(errorDictionary[error.response.data]);
          handleError(error);
        }
      }
    },
    async deletePaymentLink({ state, commit }, { paymentLink }) {
      const { paymentLinks } = state;

      try {
        await paymentLinkService.deletePaymentLink({ paymentLinkId: paymentLink._id });
        const filteredPaymentLinks = paymentLinks
          .map((payLink) => {
            if (payLink._id.toString() === paymentLink._id.toString()) {
              return {
                ...payLink,
                status: 'CANCELED',
              };
            }
            return payLink;
          });
        commit('setPaymentLinks', { paymentLinks: filteredPaymentLinks });
        return null;
      } catch (error) {
        console.log(error);
        return handleError(error);
      }
    },
    async createPaymentLink({ state, commit }) {
      const { newPaymentLink } = state;
      try {
        const paymentLinkData = {
          retailerId: utilAuth.getRetailerId(),
          ctrId: utilAuth.getUserId(),
          creatorEmail: utilAuth.getUsername(),
          customerName: newPaymentLink.customerName,
          customerPhone: filterValue({ field: 'phone', value: newPaymentLink.customerPhone }),
          customerEmail: newPaymentLink.customerEmail,
          description: newPaymentLink.description,
          value: newPaymentLink.value,
        };

        const creatorName = utilAuth.getIntraUserName();
        if (creatorName) paymentLinkData.creatorName = creatorName;

        const { data } = await paymentLinkService.createPaymentLink(paymentLinkData);

        commit('setNewPaymentLink', { newPaymentLink: data.paymentLink });
        commit('setPaymentLinkUser', { newPaymentLink: data.paymentLink });

        return { inputError: null };
      } catch (error) {
        console.log(error.response);
        if (error && error.response && error.response.data === 'Invalid email') {
          return { inputError: 'email' };
        }
        throw error;
      }
    },
    async createPreApprovedPaymentLink({ state, commit }) {
      try {
        const { paymentLinkUser } = state;
        const userInfo = sanitizeUser({ user: paymentLinkUser });
        const paymentLinkData = {
          retailerId: utilAuth.getRetailerId(),
          ctrId: utilAuth.getUserId(),
          creatorEmail: utilAuth.getUsername(),
          customerCpf: userInfo.cpf,
          customerEmail: userInfo.email,
          customerZipCode: userInfo.zipCode,
          customerPhone: userInfo.phone,
        };

        const creatorName = utilAuth.getIntraUserName();
        if (creatorName) paymentLinkData.creatorName = creatorName;

        const {
          data: {
            paymentLink,
            preApprovedCredit,
            dailyTries,
            preApproveError,
          },
        } = await paymentLinkService.createPreApprovedPaymentLink(paymentLinkData);

        commit('setVerifyCreditResult', { result: paymentLink.creditApplyStatus });
        commit('setVerifyPreApprovedCreditResult', { result: paymentLink.creditApplyStatus });
        commit('setPreApprovedCredit', { preApprovedCredit });
        commit('setMaxValueApproved', { maxValueApproved: preApprovedCredit.maxValue });

        commit('setNewPaymentLink', { newPaymentLink: paymentLink });
        commit('setPaymentLinkUser', { newPaymentLink: paymentLink });

        commit('setDailyTries', { dailyTries: dailyTries ?? 0 });
        const pendingPayment = preApproveError === 'CPF already has pending payment link';
        return { pendingPayment, dailyTries, paymentLink };
      } catch (error) {
        if (error && error.response && error.response.data === 'Max daily tries of payment link creation reached') {
          return {
            inputError: {
              maxDailyTries: true,
            },
          };
        }

        if (error && error.response && error.response.data === 'invalid email') {
          return { inputError: 'email' };
        }

        if (error && error.response && error.response.data === 'invalid cep') {
          return { inputError: 'zipCode' };
        }

        if (error && error.response && error.response.data === 'invalid cpf') {
          return { inputError: 'cpf' };
        }

        if (error && error.response && error.response.data === 'invalid phone') {
          return { inputError: 'phone' };
        }

        if (error && error.response && error.response.data) {
          return {
            message: errorDictionary[error.response.data] || error.response.data,
          };
        }

        return { inputError: null };
      }
    },
    getSanitizedPaymentLinkUser({ state }) {
      return sanitizeUser({ user: state.paymentLinkUser });
    },
    async verifyCredit({ commit, state }) {
      const { newPaymentLink } = state;

      try {
        const {
          data: {
            paymentLink,
            creditPolicyResult,
          },
        } = await paymentLinkService.applyForPreApprovedPaymentLink({
          paymentLinkId: newPaymentLink._id,
          value: newPaymentLink.value,
          description: newPaymentLink.description,
          device: isMobile() ? 'mobile' : 'desktop',
        });

        commit('setNewPaymentLink', { newPaymentLink: paymentLink });
        commit('setVerifyCreditResult', { result: creditPolicyResult.result });
        if (creditPolicyResult.retentionOptions) {
          commit('setPaymentOptions', { 0: creditPolicyResult.retentionOptions.options[0], retailerRetentionType: creditPolicyResult.retentionOptions.retailerRetentionType, retailerPreferredRetentionType: creditPolicyResult.retentionOptions.retailerPreferredRetentionType });
        } else {
          commit('setPaymentOptions', creditPolicyResult.downPaymentOptions);
        }
      } catch (error) {
        console.error(error);
        handleError(error);
      }
    },
    async getPaymentUser({ commit }, cpf) {
      try {
        const {
          data,
        } = await paymentLinkService.verifyCreditbyCPF({
          cpf: filterValue({ field: 'cpf', value: cpf }),
        });

        commit('setPaymentLinkUserData', { field: 'cpf', value: data.cpf });
        commit('setPaymentLinkUserData', { field: 'phone', value: data.phone });
        commit('setPaymentLinkUserData', { field: 'email', value: data.email });
        commit('setPaymentLinkUserData', { field: 'zipCode', value: data.zipCode });
        commit('setUserExpirationDate', { date: data.expirationDate });
        commit('setPreApprovedCredit', { preApprovedCredit: data.preApprovedCredit });
        return data;
      } catch (error) {
        return handleError(error);
      }
    },
    async updatePaymentUser({ state }) {
      const user = sanitizeUser({ user: state.paymentLinkUser });

      try {
        await paymentLinkService.updatePaymentUser({
          cpf: filterValue({ field: 'cpf', value: user.cpf }),
          user,
        });
        return null;
      } catch (error) {
        return handleError(error);
      }
    },
    async generatePaymentLink({ state, commit }, rentGuaranteeBillet) {
      const { newPaymentLink } = state;
      try {
        const dataToSend = {
          paymentLinkId: newPaymentLink._id.toString(),
        };

        if (rentGuaranteeBillet) {
          dataToSend.rentGuaranteeBillet = rentGuaranteeBillet;
        }

        const { data } = await paymentLinkService.generateLink(dataToSend);
        commit('setNewPaymentLink', { newPaymentLink: data.paymentLink });
        return null;
      } catch (error) {
        console.log(error);
        return handleError(error);
      }
    },
    async cancelTransaction({ state, commit }, { paymentLink, retailerCancelReason }) {
      const { paymentLinks } = state;
      try {
        await paymentLinkService.cancelTransaction({
          apiKey: paymentLink.retailerId,
          transactionId: paymentLink.transactionId,
          retailerCancelReason,
        });

        const filteredPaymentLinks = paymentLinks
          .map((payLink) => {
            if (payLink._id.toString() === paymentLink._id.toString()) {
              return {
                ...payLink,
                retailerCancelReason,
                isCanceledByRetailer: true,
                status: 'CANCELED',
              };
            }
            return payLink;
          });
        commit('setPaymentLinks', { paymentLinks: filteredPaymentLinks });
      } catch (error) {
        console.log(error.response);
        handleError(error);
      }
    },
    async updateOrderId({ state, commit }, { paymentLinkId, orderId }) {
      const { paymentLinks } = state;
      try {
        await paymentLinkService.updateOrderId({
          paymentLinkId,
          orderId,
        });
        const filteredPaymentLinks = paymentLinks
          .map((payLink) => {
            if (payLink._id.toString() === paymentLinkId) {
              return {
                ...payLink,
                orderId,
              };
            }
            return payLink;
          });
        commit('setPaymentLinks', { paymentLinks: filteredPaymentLinks });
      } catch (error) {
        console.log(error.response);
        throw errorDictionary[error.message] || error;
      }
    },
    async getPaymentOptions({ commit }, { paymentLinkId }) {
      try {
        const { data } = await paymentLinkService.getPaymentOptions({ paymentLinkId });
        if (data.retentionOptions) {
          commit('setPaymentOptions', data.retentionOptions);
        } else {
          commit('setPaymentOptions', data.options);
        }
      } catch (error) {
        console.log(error.response);
        handleError(error);
      }
    },
    async getPaymentOptionChosen({ commit }, { paymentLinkId }) {
      try {
        const { data } = await paymentLinkService.getPaymentOptionChosen({ paymentLinkId });
        commit('setChosenPaymentOption', data.option);
      } catch (error) {
        console.log(error.response);
        handleError(error);
      }
    },
    setCurrentPaymentLink({ commit }, { paymentLink }) {
      commit('setCurrentPaymentLink', paymentLink);
    },
    async getOnboardingStep({ commit }, { paymentLink }) {
      try {
        if (!paymentLink.transactionId) {
          if (paymentLink.creditApplyStatus === 'PENDING') {
            return commit('setOnboardingStep', { step: 'PENDING_VERIFY_CREDIT' });
          }
          return commit('setOnboardingStep', { step: null });
        }
        const { data } = await paymentLinkService.getPaymentOptionOnboardingStep({
          paymentLinkId: paymentLink._id,
        });
        return commit('setOnboardingStep', { step: data.step });
      } catch (error) {
        console.log(error.response);
        throw errorDictionary[error.message] || error;
      }
    },
    async getCreators({ commit }, { retailerId }) {
      try {
        const { data } = await paymentLinkService.getCreators({ retailerId });
        commit('setCreators', { creators: data.intraUsers });
      } catch (error) {
        console.log(error.response);
        throw errorDictionary[error.message] || error;
      }
    },
    async getMaxValueApproved({ commit }, { paymentLinkId }) {
      try {
        const { data } = await paymentLinkService.getMaxValueApproved({ paymentLinkId });
        commit('setMaxValueApproved', { maxValueApproved: data.maxValueApproved });
      } catch (error) {
        console.log(error.response);
        throw errorDictionary[error.message] || error;
      }
    },
    async getDataForPaymentLinkCreation({ commit }, { preApprovedCreditId, cpf }) {
      try {
        const { data } = await paymentLinkService
          .getDataForPaymentLinkCreation({ preApprovedCreditId, cpf });

        commit('setPreApprovedCredit', { preApprovedCredit: data.preApprovedCredit });
        commit('setDailyTries', { dailyTries: data.dailyTries ?? 0 });
      } catch (error) {
        console.log(error.response);
        throw errorDictionary[error.message] || error;
      }
    },
    resetMaxValueApproved({ commit }) {
      commit('setMaxValueApproved', { maxValueApproved: null });
    },
    setNewPaymentLinkData({ commit }, { field, value }) {
      commit('setNewPaymentLinkData', { field, value });
    },
    setPaymentLinkUserData({ commit }, { field, value }) {
      commit('setPaymentLinkUserData', { field, value });
    },
    resetPaymentLinkCreation({ commit }) {
      commit('resetPaymentLinkCreation');
    },
    setDrawerLoading({ commit }, isDrawerLoading) {
      commit('setDrawerLoading', isDrawerLoading);
    },
    setPaymentOptions({ commit }, options) {
      commit('setPaymentOptions', options);
    },
    setChosenPaymentOption({ commit }, chosenPaymentOption) {
      commit('setChosenPaymentOption', chosenPaymentOption);
    },
  },
  mutations: {
    setTotalOfPaymentLinks(state, { totalCount }) {
      state.totalOfPaymentLinks = parseInt(totalCount, 10);
    },
    setPaymentLinks(state, { paymentLinks }) {
      state.paymentLinks = paymentLinks;
    },
    setNewPaymentLinkData(state, { field, value }) {
      state.newPaymentLink[field] = value;
    },
    setPaymentLinkUserData(state, { field, value }) {
      state.paymentLinkUser[field] = value;
    },
    setNewPaymentLink(state, { newPaymentLink }) {
      state.newPaymentLink = newPaymentLink;
    },
    setUserData(state, { user }) {
      state.paymentLinkUser = user;
    },
    setUserExpirationDate(state, { date }) {
      state.expirationDate = date;
    },
    setPaymentLinkUser(state, { newPaymentLink }) {
      state.paymentLinkUser = {
        phone: newPaymentLink.customerPhone,
        email: newPaymentLink.customerEmail,
        zipCode: newPaymentLink.customerZipCode,
        cpf: newPaymentLink.customerCpf,
      };
    },
    setVerifyCreditResult(state, { result }) {
      state.verifyCreditResult = result;
    },
    setVerifyPreApprovedCreditResult(state, { result }) {
      state.verifyPreApprovedCreditResult = result;
    },
    setPreApprovedCredit(state, { preApprovedCredit }) {
      state.preApprovedCredit = preApprovedCredit;
    },
    resetPaymentLinkCreation(state) {
      state.newPaymentLink = {};
      state.paymentLinkUser = {
        phone: null,
        cpf: null,
        email: null,
        zipCode: null,
      };
      state.verifyCreditResult = null;
      state.verifyPreApprovedCreditResult = null;
      state.preApprovedCredit = null;
      state.dailyTries = 0;
    },
    setAllowedToUsePaymentLink(state, allowedToUsePaymentLink) {
      state.allowedToUsePaymentLink = allowedToUsePaymentLink;
    },
    setPaymentOptions(state, options) {
      if (!Array.isArray(options) && options) {
        const { downPaymentPercentage,
          downPaymentValue,
          economyValue,
          hasGracePeriod,
          maxOfMaxTotalAmount,
          minOfMinTotalAmount,
          options: optionsArray,
        } = options['0'];
        const promotionalInstallments = optionsArray[0].installmentOptions.filter((option) => option.retentionType === 'promotional');
        const specialInstallments = optionsArray[0].installmentOptions.filter((option) => option.retentionType === 'special');
        let filteredInstallments = (options.retailerRetentionType === 'promotional' || options.retailerRetentionType === 'all') ? promotionalInstallments : specialInstallments;
        if (options.retailerPreferredRetentionType) {
          filteredInstallments = options.retailerPreferredRetentionType === 'promotional' ? promotionalInstallments : specialInstallments;
        }
        state.paymentOptions = [{
          downPaymentPercentage,
          downPaymentValue,
          economyValue,
          hasGracePeriod,
          maxOfMaxTotalAmount,
          minOfMinTotalAmount,
          options: [{
            gracePeriodDueDate: optionsArray[0].gracePeriodDueDate,
            installmentOptions: filteredInstallments,
            maxTotalAmount: optionsArray[0].maxTotalAmount,
            minTotalAmount: optionsArray[0].minTotalAmount,
          }],
        }];
        state.retailerRetentionType = options.retailerRetentionType;
      } else {
        state.paymentOptions = options;
        state.retailerRetentionType = undefined;
      }
    },
    setOnboardingStep(state, { step }) {
      state.onboardingStep = step;
    },
    setCurrentPaymentLink(state, paymentLink) {
      state.currentPaymentLink = paymentLink;
    },
    setCreators(state, { creators }) {
      state.creators = creators;
    },
    setDrawerLoading(state, isDrawerLoading) {
      state.isDrawerLoading = isDrawerLoading;
    },
    setChosenPaymentOption(state, chosenPaymentOption) {
      state.chosenPaymentOption = chosenPaymentOption;
    },
    setMaxValueApproved(state, { maxValueApproved }) {
      state.maxValueApproved = maxValueApproved;
    },
    setDailyTries(state, { dailyTries }) {
      state.dailyTries = dailyTries;
    },
  },
};
