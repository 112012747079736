export default {
  namespaced: true,
  state: {
    isLoading: false,
    loadingMessage: null,
    showIcon: null,
    showIconDelay: null,
  },
  actions: {
    setIsLoading({ commit }, { isLoading, loadingMessage, showIcon, showIconDelay }) {
      if (!isLoading && showIcon) {
        commit('setIconInfo', { showIcon, showIconDelay });
      } else {
        commit('setIsLoading', { isLoading });
        commit('setLoadingMessage', { loadingMessage });
      }
    },
  },
  mutations: {
    setIsLoading(state, { isLoading }) {
      state.isLoading = isLoading;
    },
    setLoadingMessage(state, { loadingMessage }) {
      state.loadingMessage = loadingMessage;
    },
    setIconInfo(state, { showIcon, showIconDelay }) {
      state.showIcon = showIcon;
      state.showIconDelay = showIconDelay;
      state.loadingMessage = null;

      setTimeout(() => {
        state.isLoading = false;
        state.showIcon = false;
        state.showIconDelay = null;
      }, showIconDelay);
    },
  },
};
