export default [
  {
    path: '/link-de-pagamento',
    component: () => import('./views/PaymentLink.vue'),
    name: 'payment-link-list',
  },
  {
    path: '/link-de-pagamento/novo',
    component: () => import('./views/CreatePaymentLink.vue'),
    name: 'create-payment-link',
  },
  {
    path: 'link-de-pagamento/verificar-credito',
    component: () => import('./views/CreditVerify.vue'),
    name: 'credit-verify',
  },
  {
    path: 'link-de-pagamento/verificacao-de-credito',
    component: () => import('./views/CreditCheck.vue'),
    name: 'credit-check',
  },
  {
    path: 'link-de-pagamento/credito-verificado',
    component: () => import('./views/CreditVerified.vue'),
    name: 'credit-verified',
    props: true,
  },
  {
    path: 'link-de-pagamento/negado',
    component: () => import('./views/DeniedPaymentLink.vue'),
    name: 'denied-payment-link',
  },
  {
    path: 'link-de-pagamento/resumo',
    component: () => import('./views/ReviewPaymentLink.vue'),
    name: 'review-payment-link',
  },
  {
    path: 'link-de-pagamento/aprovado',
    component: () => import('./views/SuccessPaymentLink.vue'),
    name: 'success-payment-link',
  },
  {
    path: 'link-de-pagamento/numero-de-links-excedido',
    component: () => import('./views/NumberOfLinkApplicationExceeded.vue'),
    name: 'payment-link-number-of-link-exceeded',
  },
  {
    path: 'link-de-pagamento/link-associado',
    component: () => import('./views/LinkAlreadyAssociatedToCPF.vue'),
    name: 'link-already-associated-to-cpf',
  },
];
