/* eslint-disable no-param-reassign */
import ParcelexApi from '@/services/parcelex';
import router from '@/router';

import onboardingUtil from '../util/util.onboarding';

const annualIncomeDTO = {
  'Até R$ 99k': '99k',
  'De R$ 100k a R$ 999k': '100k-999k',
  'De R$ 1MM a R$ 4.9MM': '1MM-4.9MM',
  'De R$ 5MM a R$ 19.9MM': '5MM-19.9MM',
  'De R$ 20MM a R$ 49.9MM': '20MM-49.9MM',
  'De R$ 50MM a R$ 200MM': '50MM-199MM',
  'Acima de R$ 200MM': '200MM',
};

const pixTypeDTO = {
  CNPJ: 'cpf/cnpj',
  Email: 'email',
  Celular: 'celular',
  /* eslint-disable-next-line quote-props */
  'Aleatória': 'aleatorio',
};

const getPropertyName = (object, valueSearched) => {
  const property = Object.entries(object).find(([, value]) => valueSearched === value);
  return property ? property[0] : valueSearched;
};

export default {
  namespaced: true,
  state: {
    onboardingSteps: [
      'Dados cadastrais',
      'Dados comerciais',
      'Endereço comercial',
      'Informações financeiras',
      'Contatos',
      'Aparência do checkout',
      'Instalação',
    ],
    annualIncomeOptions: [
      'Até R$ 99k',
      'De R$ 100k a R$ 999k',
      'De R$ 1MM a R$ 4.9MM',
      'De R$ 5MM a R$ 19.9MM',
      'De R$ 20MM a R$ 49.9MM',
      'De R$ 50MM a R$ 200MM',
      'Acima de R$ 200MM',
    ],
    siteProtocolOptions: ['https://', 'http://'],
    ecommercePlatformOptions: [
      'Nuvemshop',
      'VTEX',
      'Precode',
      'Plataforma própria',
      'Outra',
    ],
    brazilianStates: [
      'AC',
      'AL',
      'AP',
      'AM',
      'BA',
      'CE',
      'DF',
      'ES',
      'GO',
      'MA',
      'MT',
      'MS',
      'MG',
      'PA',
      'PB',
      'PR',
      'PE',
      'PI',
      'RJ',
      'RN',
      'RS',
      'RO',
      'RR',
      'SC',
      'SP',
      'SE',
      'TO',
    ],
    pixTypes: ['CNPJ', 'Email', 'Celular', 'Aleatória'],

    user: {
      _id: '',
      name: '',
      lastname: '',
      phone: '',
    },
    retailer: {
      _id: onboardingUtil.getRetailerId() || null,
      cnpj: '',
      name: '',
      businessName: '',
      annualRevenue: '',
      averageTicket: 0,
      platform: '',
      platformType: '',
      checkoutUrl: '',
      shopName: '',
      noDigitalMarket: false,
      zipCode: '',
      address: '',
      number: '',
      complement: null,
      district: '',
      state: '',
      city: '',
      STEP: '',
    },
    retailerExtension: {
      pix: null,
      bank: null,
      managerData: [],
      emailLogo: '',
      primaryColor: '',
    },
    managerData: {
      name: null,
      lastname: null,
    },
    selectorOptions: [
      { label: 'Chave pix', id: 0, selected: false },
      { label: 'Conta bancária', id: 1, selected: false },
    ],
  },
  getters: {
    businessData(state) {
      return {
        cnpj: state.retailer.cnpj,
        companyName: state.retailer.name,
        businessName: state.retailer.businessName,
        annualIncome: getPropertyName(annualIncomeDTO, state.retailer.annualRevenue),
        averageTicket: state.retailer.averageTicket * 100,
      };
    },
    managersData(state) {
      return state.retailerExtension.managerData.reduce((returnedObject, current) => {
        returnedObject[`${current.type}ManagerName`] = current.name;
        returnedObject[`${current.type}ManagerEmail`] = current.email;
        return returnedObject;
      }, {});
    },
    retailer(state) {
      return state.retailer;
    },
  },
  mutations: {
    setUserData(state, { _id, name, lastname, phone }) {
      state.user._id = _id;
      state.user.name = name;
      state.user.lastname = lastname;
      state.user.phone = phone;
    },
    setManagerData(state, { name, lastname }) {
      state.managerData.name = name;
      state.managerData.lastname = lastname;
    },
    setBusinessData(
      state,
      {
        cnpj,
        companyName,
        businessName,
        cnae,
        annualIncome,
        averageTicket,
        siteURL,
        ecommercePlatform,
        step,
      },
    ) {
      state.retailer.cnpj = cnpj;
      state.retailer.name = companyName;
      state.retailer.businessName = businessName;
      state.retailer.cnae = cnae;
      state.retailer.annualRevenue = annualIncomeDTO[annualIncome];
      state.retailer.averageTicket = averageTicket;
      state.retailer.checkoutUrl = siteURL;
      state.retailer.platform = ecommercePlatform === 'VTEX'
        ? 'Vtex'
        : ecommercePlatform;
      state.retailer.step = step;
    },
    setBusinessAddress(
      state,
      { cep, street, number, complement, district, stateAddress, city },
    ) {
      state.retailer.zipCode = cep;
      state.retailer.address = street;
      state.retailer.number = number;
      state.retailer.complement = complement;
      state.retailer.district = district;
      state.retailer.state = stateAddress;
      state.retailer.city = city;
    },
    setFinanceData(state, { bank, pix }) {
      state.retailerExtension.bank = bank;
      state.retailerExtension.pix = pix
        ? { key: pix.key, type: pixTypeDTO[pix.type] }
        : null;
    },
    setManagersData(state, {
      technicalManagerName,
      technicalManagerEmail,
      financeManagerName,
      financeManagerEmail,
      serviceManagerName,
      serviceManagerEmail,
    }) {
      state.retailerExtension.managerData = [
        {
          name: technicalManagerName,
          email: technicalManagerEmail,
          type: 'technical',
        },
        {
          name: financeManagerName,
          email: financeManagerEmail,
          type: 'finance',
        },
        {
          name: serviceManagerName,
          email: serviceManagerEmail,
          type: 'service',
        },
      ];
    },
    setLogoData(state, { imageURL, backgroundColor }) {
      state.retailerExtension.emailLogo = imageURL;
      state.retailerExtension.primaryColor = backgroundColor;
    },
    setRetailerId(state, id) {
      state.retailer._id = id;
      onboardingUtil.setRetailerId(id);
    },
    setUserName(userName) {
      onboardingUtil.setUserName(userName);
    },
    getUserName(state) {
      state.user.name = onboardingUtil.getUserName();
    },
    setStep(state, step) {
      state.retailer.STEP = step;
    },
    setPlatformData(state, { platform, platformType, checkoutUrl, shopName, noDigitalMarket }) {
      state.retailer.platform = platform;
      state.retailer.platformType = platformType;
      state.retailer.checkoutUrl = checkoutUrl;
      state.retailer.shopName = shopName;
      state.retailer.noDigitalMarket = noDigitalMarket;
    },
    setLoading(state, loading) {
      if (loading === false);
      setTimeout(() => { state.loading = loading; }, 1000);
    },
  },
  actions: {
    clearInitialData({ commit }) {
      commit('setUserData', {
        _id: null,
        name: null,
        lastname: null,
        phone: null,
      });

      commit('setBusinessData', {
        cnpj: null,
        companyName: null,
        businessName: null,
        cnae: { id: null, description: null },
        annualIncome: null,
        averageTicket: null,
        siteURL: null,
        ecommercePlatform: null,
        step: null,
      });

      commit('setBusinessAddress', {
        cep: null,
        street: null,
        number: null,
        complement: null,
        district: null,
        stateAddress: null,
        city: null,
      });

      commit('setFinanceData', {
        bank: null,
        pix: null,
      });

      commit('setManagersData', {
        technicalManagerName: null,
        technicalManagerEmail: null,
        financeManagerName: null,
        financeManagerEmail: null,
        serviceManagerName: null,
        serviceManagerEmail: null,
      });

      commit('setLogoData', {
        imageURL: null,
        backgroundColor: null,
      });

      commit('setRetailerId', null);

      commit('setUserName', null);
    },
    async saveInitialData({ state, commit }, { uuid }) {
      try {
        const response = await ParcelexApi
          .post('/central/user/onboarding/initial', {
            user: state.user,
            retailer: {
              cnpj: state.retailer.cnpj,
              name: state.retailer.name,
              businessName: state.retailer.businessName,
              annualRevenue: state.retailer.annualRevenue,
              averageTicket: state.retailer.averageTicket,
            },
            uuid,
          });

        commit('setRetailerId', response.data.retailer._id);
        commit('setUserName', state.user.name);
        commit('setStep', 'SECOND_STEP');
        router.push('/onboarding/endereco-comercial');
        return {};
      } catch (error) {
        if (error
          && error.response
          && error.response.data
          && error.response.data.message === 'CNPJ already registered'
        ) {
          return { errors: ['CNPJ already registered'] };
        }
        throw error;
      }
    },
    async saveMiddleStep({ state, commit }) {
      const response = {
        retailer: {
          _id: state.retailer._id || onboardingUtil.getRetailerId(),
          zipCode: state.retailer.zipCode,
          address: state.retailer.address,
          number: state.retailer.number,
          complement: state.retailer.complement,
          district: state.retailer.district,
          state: state.retailer.state,
          city: state.retailer.city,
        },
        finantialData: {},
      };
      if (state.retailerExtension.pix) {
        response.finantialData.pix = state.retailerExtension.pix;
      } else {
        response.finantialData.bank = state.retailerExtension.bank;
      }

      await ParcelexApi.patch('/central/user/onboarding/middlestep', response);
      commit('setStep', 'THIRD_STEP');
      router.push('/onboarding/contatos');
    },
    async saveFinalStep({ state, commit }) {
      const response = {
        retailerData: {
          _id: onboardingUtil.getRetailerId(),
          managerData: state.retailerExtension.managerData,
          emailLogo: state.retailerExtension.emailLogo,
          primaryColor: state.retailerExtension.primaryColor,
        },
      };

      await ParcelexApi.patch('/central/user/onboarding/finalstep', response);
      commit('setStep', 'FINAL_STEP');
      router.push('/onboarding/instalacao');
    },
    async saveInstallationInfo({ state, commit }, { uuid }) {
      const response = {
        retailerData: {
          _id: onboardingUtil.getRetailerId(),
          platform: state.retailer.platform,
          platformType: state.retailer.platformType,
          checkoutUrl: state.retailer.checkoutUrl,
          shopName: state.retailer.shopName,
          noDigitalMarket: state.retailer.noDigitalMarket,
        },
      };

      await ParcelexApi.patch('/central/user/onboarding/installationinfo', response);
      if (response.retailerData.platform === 'Nuvemshop' && !uuid) {
        commit('setStep', 'INSTALLATION');
        commit('setStep', 'INSTALLATION');
        router.push('/onboarding/instalacao-nuvemshop');
      } else {
        commit('setStep', 'IN_ANALYSIS');
        router.push('/onboarding/cadastro-analise');
      }
    },
    async updateInstallationStep({ state, commit }, { uuid, retailerId }) {
      const computedRetailerId = onboardingUtil.getRetailerId() || state.retailer._id || retailerId;

      await ParcelexApi.post(`/central/user/onboarding/retailer/${computedRetailerId}/finish/installation`, {
        uuid,
      });

      commit('setStep', 'IN_ANALYSIS');
      router.push('/onboarding/cadastro-analise');
    },
    async getUserById({ state, commit }) {
      if (state.managerData.name && state.managerData.lastname) {
        return state.managerData;
      }

      const param = {
        id: onboardingUtil.getRetailerId(),
      };
      const response = await ParcelexApi.post('/central/user/onboarding/retailerdata', param);

      commit('setManagerData', {
        name: response.data.name,
        lastname: response.data.lastname,
      });

      return response.data;
    },
    setRetailerId({ commit }, { id }) {
      commit('setRetailerId', id);
    },
    setLoading({ commit }, { loading }) {
      commit('setLoading', loading);
    },
  },
};
