export default [
  {
    path: '/repasses',
    component: () => import('./views/Transfers.vue'),
    name: 'transfers',
  },
  {
    path: '/repasses-pendentes',
    component: () => import('./views/TransfersPending.vue'),
    name: 'transfers-pending',
  },
];
