import { v4 as uuidv4 } from 'uuid';

import ParcelexApi from '@/services/parcelex';
import router from '@/router';
import authUtil from '../util/util.auth';

export default {
  namespaced: true,
  state: {
    userId: null,
    username: null,
    name: null,
    lastname: null,
    token: authUtil.getToken(),
    verifyStatus: null,
    uuid: null,
    agreedCookies: false,
    hierarchy: null,
  },
  actions: {
    clearInitialData({ commit }) {
      commit('setUUID', null);
      commit('setVerifyStatus', null);
      commit('logout');
    },
    async register({ commit, dispatch, state }, { username, password }) {
      try {
        const { data } = await ParcelexApi.post('/central/user/register', { username, password, uuid: state.uuid });
        commit('register', { userId: data.userId, username });
        router.push('/registrar/autenticacao');
      } catch (err) {
        if (err.response.data === 'User already registred' && state.uuid) {
          dispatch('login', { username, password, showAlert: false });
        } else {
          dispatch('alert/error', 'Erro ao cadastrar', { root: true });
        }
      }
    },
    verify({ commit, state }, { securityCode }) {
      commit('verifyWait');
      ParcelexApi
        .post('/central/user/verify', { userId: state.userId, securityCode })
        .then((res) => {
          commit('verifySuccess', {
            token: res.data.token,
          });
        })
        .catch((err) => {
          console.error(err);
          commit('verifyFailure');
        });
    },
    async login({ state, commit, dispatch }, { username, password /* ,showAlert = false  */ }) {
      try {
        const { data } = await ParcelexApi.post('/central/user/login', { username, password });
        const { token, step, retailerId, userId, name, lastname, hierarchy } = data;
        commit('loginSuccess', {
          token,
          username,
          step,
          retailerId,
          userId,
          name,
          lastname,
          hierarchy,
        });

        // if (showAlert) dispatch('alert/success', 'Login feito com sucesso!', { root: true });

        if (step === 'COMPLETED') {
          window.location.href = '../pedidos';
        } else if (step === 'INSTALLATION' && state.uuid) {
          dispatch('onboarding/updateInstallationStep', { uuid: state.uuid, retailerId }, { root: true });
        } else {
          const path = authUtil.getStepPath({ step });
          router.push(path);
        }

        return { success: true };
      } catch (error) {
        console.error(error);
        dispatch('alert/error', 'Login inválido!', { root: true });
        commit('loginFailure', { username });
        return { success: true };
      }
    },
    logout({ commit, dispatch }) {
      commit('logout');
      dispatch('onboarding/clearInitialData', { root: true });
      window.location.href = '/login';
    },
    async verifyNuvemshopCode({ commit }, code) {
      const uuid = uuidv4();
      await ParcelexApi.post('/central/user/nuvemshopCode', { code, uuid });
      commit('setUUID', uuid);
    },
    async recoverPassword({ commit }, { email }) {
      try {
        await ParcelexApi.post(
          '/central/user/recoverPassword/verifyEmail',
          { email },
        );
        commit('setUsername', { username: email });
        router.push('/verificar-email');

        return { success: true, error: null };
      } catch (error) {
        console.error(error);
        return { error: error.response.data, success: false };
      }
    },
    async changePassword({ dispatch, commit }, {
      username,
      newPassword,
    }) {
      try {
        await ParcelexApi.post(
          '/central/user/recoverPassword/registerNewPassword',
          {
            email: username,
            password: newPassword,
          },
        );
        dispatch('alert/success', 'Senha alterada com sucesso!', { root: true });
        commit('logout');
        router.push('/login');
      } catch (error) {
        console.log(error);
        dispatch('alert/error', error.message, { root: true });
        console.error(error);
      }
    },
    setAuthentication({ commit }, { token, username, step, retailerId, userId, name, lastname }) {
      commit('loginSuccess', { token, username, step, retailerId, userId, name, lastname });
    },
  },
  mutations: {
    register(state, { userId, username }) {
      state.userId = userId;
      state.username = username;
      authUtil.setAuth({ userId, username });
    },
    setVerifyStatus(state, value) {
      state.verifyStatus = value;
    },
    verifyWait(state) {
      state.verifyStatus = 'verifyWait';
    },
    verifyFailure(state) {
      state.verifyStatus = 'verifyFailure';
    },
    verifySuccess(state, { token }) {
      state.verifyStatus = 'verifySuccess';
      authUtil.setAuth({ token });
      setTimeout(() => {
        router.push('/onboarding/dados-cadastrais');
      }, 500);
    },
    loginSuccess(state, { token, username, retailerId, step, userId, name, lastname, hierarchy }) {
      state.token = token;
      state.username = username;
      state.retailerId = retailerId;
      state.step = step;
      state.userId = userId;
      state.name = name;
      state.lastname = lastname;
      state.hierarchy = hierarchy;
      authUtil.setAuth({ username, token, retailerId, userId, name, lastname, hierarchy });
    },
    loginFailure(state, { username }) {
      state.token = null;
      state.username = username;
      authUtil.unsetAuth();
    },
    logout(state) {
      state.token = null;
      state.username = null;
      state.userId = null;
      authUtil.unsetAuth();
    },
    setUUID(state, value) {
      state.uuid = value;
    },
    setAgreedCookies(state, value) {
      state.agreedCookies = value;
    },
    setUsername(state, { username }) {
      state.username = username;
    },
    setToken(state, { token }) {
      state.token = token;
      authUtil.setToken({ token });
    },
  },
};
