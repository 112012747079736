import jwtDecode from 'jwt-decode';

import retailerService from './retailer.service';
import authUtil from '../../util/util.auth';

export default {
  namespaced: true,
  state: {
    retailers: [],
    currentRetailer: null,
  },
  actions: {
    async getRetailers({ commit }) {
      try {
        const token = localStorage.getItem('token');
        const { retailerIds } = jwtDecode(token);
        const { data: retailers } = await retailerService.getRetailersByIds({ retailerIds });
        commit('setRetailers', { retailers });

        const storedRetailerId = authUtil.getRetailerId();
        const storedRetailer = retailers.find((retailer) => retailer._id === storedRetailerId);
        const currentRetailer = storedRetailer || (retailers && retailers[0]);
        commit('setCurrentRetailer', { retailer: currentRetailer });
      } catch (error) {
        console.error(error);
      }
    },
    changeRetailer({ commit }, { retailer }) {
      commit('setCurrentRetailer', { retailer });
    },
  },
  mutations: {
    setRetailers(state, { retailers }) {
      state.retailers = retailers;
    },
    setCurrentRetailer(state, { retailer }) {
      state.currentRetailer = retailer;
      authUtil.setRetailerId({ retailerId: retailer._id });
    },
  },
};
