export default [
  {
    path: '/template-onboarding',
    component: () => import('./views/TemplateOnboarding.vue'),
    name: 'template-onboarding',
  },
  {
    path: '/template-installation',
    component: () => import('./views/TemplateInstallation.vue'),
    name: 'template-installation',
  },
  {
    path: '/onboarding/dados-cadastrais',
    component: () => import('./views/RegistrationData.vue'),
    name: 'RegistrationData',
  },
  {
    path: '/onboarding/dados-comerciais',
    component: () => import('./views/CommercialData.vue'),
    name: 'CommercialData',
  },
  {
    path: '/onboarding/endereco-comercial',
    component: () => import('./views/BusinessAddress.vue'),
    name: 'BusinessAddress',
  },
  {
    path: '/onboarding/cadastro-analise',
    component: () => import('./views/RegistrationUnderReview.vue'),
    name: 'RegistrationUnderReview',
  },
  {
    path: '/onboarding/cadastro-reprovado',
    component: () => import('./views/ReprovedRegistration.vue'),
    name: 'ReprovedRegistration',
  },
  {
    path: '/onboarding/informacoes-financeiras',
    component: () => import('./views/FinancialInformation.vue'),
    name: 'FinancialInformation',
  },
  {
    path: '/onboarding/contatos',
    component: () => import('./views/Contacts.vue'),
    name: 'Contacts',
  },
  {
    path: '/onboarding/aparencia-checkout',
    component: () => import('./views/CheckoutAppearance.vue'),
    name: 'CheckoutAppearance',
  },
  {
    path: '/onboarding/instalacao',
    component: () => import('./views/Installation.vue'),
    name: 'Installation',
  },
  {
    path: '/onboarding/instalacao-nuvemshop',
    component: () => import('./views/NuvemshopInstalation.vue'),
    name: 'NuvemshopInstalation',
  },
];
