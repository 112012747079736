export default {
  namespaced: true,
  state: {
    showToast: false,
    toastMessage: null,
    toastType: null,
  },
  actions: {
    showToast({ commit }, { toastMessage, toastType, hideToastDelay }) {
      commit('showToast', { toastMessage, toastType, hideToastDelay });
    },
    hideToast({ commit }) {
      commit('hideToast');
    },
  },
  mutations: {
    showToast(state, { toastMessage, toastType, hideToastDelay }) {
      state.toastMessage = toastMessage;
      state.toastType = toastType;
      state.showToast = true;

      setTimeout(() => {
        state.showToast = false;
        state.toastMessage = null;
        state.toastType = null;
        state.hideToastDelay = null;
      }, hideToastDelay || 3000);
    },
    setLoadingMessage(state, { loadingMessage }) {
      state.loadingMessage = loadingMessage;
    },
    setIconInfo(state, { showIcon, showIconDelay }) {
      state.showIcon = showIcon;
      state.showIconDelay = showIconDelay;
      state.loadingMessage = null;
    },
    hideToast(state) {
      state.showToast = false;
      state.toastMessage = null;
      state.toastType = null;
      state.hideToastDelay = null;
    },
  },
};
