import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';
import localForage from 'localforage';
import * as memoryDriver from 'localforage-driver-memory';

import authStore from './auth.store';
import onboardingStore from './onboarding.store';
import paymentLinkStore from '../feature/paymentLink/paymentLink.store';
import retailerStore from '../feature/retailer/retailer.store';
import loadingStore from '../feature/loading/loading.store';
import toastStore from '../components/common/Toast/toast.store';
import reportsStore from '../feature/reports/reports.store';
import ordersStore from '../feature/orders/orders.store';
import financesStore from '../feature/finance/finances.store';

Vue.use(Vuex);

localForage.defineDriver(memoryDriver);
localForage
  .setDriver([
    localForage.LOCALSTORAGE,
    localForage.INDEXEDDB,
    localForage.WEBSQL,
    // eslint-disable-next-line no-underscore-dangle
    memoryDriver._driver,
  ]);

const vuexLocal = new VuexPersistence({
  strictMode: true,
  storage: localForage,
  asyncStorage: true,
  reducer: (state) => ({
    auth: state.auth,
    onboarding: state.onboarding,
  }),
  filter: ({ type }) => ![
    'auth/verifyStatus',
  ].includes(type),
});

export default new Vuex.Store({
  plugins: [vuexLocal.plugin],
  modules: {
    auth: authStore,
    onboarding: onboardingStore,
    paymentLink: paymentLinkStore,
    retailer: retailerStore,
    loading: loadingStore,
    toast: toastStore,
    reports: reportsStore,
    orders: ordersStore,
    finances: financesStore,
  },
  mutations: {
    RESTORE_MUTATION: vuexLocal.RESTORE_MUTATION,
  },
});
