import querystring from 'querystring';
import ParcelexApi from '@/services/parcelex';

function getAuthHeader() {
  const token = localStorage.getItem('token');
  if (!token) return {};
  return { Authorization: `Bearer ${token}` };
}

async function getOrdersByRetailer({
  retailerId,
  startDate,
  endDate,
  search,
  status,
  paymentStatus,
  limit,
  offset,
  sort,
  canViewAll,
  intraUserId,
}) {
  const queryObject = { retailerId, startDate, endDate, limit, offset, sort };
  if (search) queryObject.search = search;
  if (status) queryObject.status = status;
  if (paymentStatus) queryObject.paymentStatus = paymentStatus;
  if (canViewAll === false && intraUserId) {
    queryObject.canViewAll = canViewAll;
    queryObject.intraUserId = intraUserId;
  }
  const queryParams = querystring.stringify(queryObject);

  const response = await ParcelexApi.get(`/central/transactions?${queryParams}`, {
    headers: getAuthHeader(),
  });
  return {
    orders: response.data,
    totalOfOrders: response.headers['x-total-count'.toLowerCase()],
  };
}

async function getInstallmentsBilletPDF({ transactionId }) {
  const response = await ParcelexApi.get(`/central/transactions/${transactionId}/billets`, {
    headers: getAuthHeader(),
  });
  return response.data;
}

export default {
  getOrdersByRetailer,
  getInstallmentsBilletPDF,
};
