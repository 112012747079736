export default [
  {
    path: '/api',
    component: () => import('./views/Api.vue'),
    name: 'api',
  },
  {
    path: '/aparencia',
    component: () => import('./views/Appearance.vue'),
    name: 'appearance',
  },
];
