import FinanceService from './finance.service';
// import utilAuth from '@/util/util.auth';
// import stringMixin from '@/mixins/string';

export default {
  namespaced: true,
  state: {
    totalOfTransfers: 0,
    totalAmount: 0,
    transfers: [],
    totalOfPendingTransfers: 0,
    pendingTransfers: [],
  },
  actions: {
    async getTransfers({ commit }, {
      startDate,
      endDate,
      retailerId,
      search,
      limit,
      offset,
      channel,
      sort,
    }) {
      try {
        const {
          transfers, totalOfTransfers, totalOfAmount,
        } = await FinanceService.getTransfersData({
          startDate,
          endDate,
          retailerId,
          search,
          limit,
          offset,
          channel,
          sort,
        });
        commit('setTransfers', { transfers });
        commit('setTotalOfTransfers', { totalOfTransfers });
        commit('setTotalAmounts', { totalOfAmount });
      } catch (error) {
        if (!error.response) {
          console.error(error);
          throw error;
        }
      }
    },
    async getTransactionsToTransfers({ commit }, {
      startDate,
      endDate,
      retailerId,
      search,
      channel,
      limit,
      offset,
      sort,
    }) {
      try {
        const { pendingTransfers, totalOfPendingTransfers, totalOfAmount } = await FinanceService
          .getTransactionsToTransferData({
            startDate,
            endDate,
            retailerId,
            search,
            channel,
            limit,
            offset,
            sort,
          });
        commit('setPendingTransfers', { pendingTransfers });
        commit('setTotalOfPendingTransfers', { totalOfPendingTransfers });
        commit('setTotalAmounts', { totalOfAmount });
      } catch (error) {
        if (!error.response) {
          console.error(error);
          throw error;
        }
      }
    },
  },
  mutations: {
    setTransfers(state, { transfers }) {
      state.transfers = transfers;
    },
    setTotalOfTransfers(state, { totalOfTransfers }) {
      state.totalOfTransfers = parseInt(totalOfTransfers, 10);
    },
    setTotalAmounts(state, { totalOfAmount }) {
      state.totalAmount = parseInt(totalOfAmount, 10);
    },
    setPendingTransfers(state, { pendingTransfers }) {
      state.pendingTransfers = pendingTransfers;
    },
    setTotalOfPendingTransfers(state, { totalOfPendingTransfers }) {
      state.totalOfPendingTransfers = parseInt(totalOfPendingTransfers, 10);
    },
  },
};
